<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header errors">
      <div></div>
      <!-- <div class="img"><img src="@/assets/img/title-w.svg" /></div> -->
      <div class="error-title" style="color: white;font-size: 18px;">雲林幣扭一下</div>
      <div></div>
    </section>
    <!-- Main -->
    <div class="main errors">
      <div class="img"><img src="@/assets/icon/error.svg" /></div>
      <div class="flexV center">
        <!-- <span class="fXXL white">網站錯誤</span> -->

        <span v-if="errorMsg" class="fL secondary"> {{ errorMsg }} </span>
        <span v-else class="fL secondary">付款連結已失效，請重新掃碼付款</span>
        <!-- <span v-else class="fL secondary">無法取得訂單資訊，請稍後重試</span> -->
      </div>
      <a class="button fit rounded white" @click.prevent="$router.push('/scanner')"
        ><span class="fL primary">重新掃碼付款</span></a
      >
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";

export default {
  name: 'PaymentError',
  data() {
    return {
      // 支援i18n的 errorCode
      errorCodes: ['OR001', 'OR002', 'OR003', 'OR004', 'OR005',
       'OR006', 'OR007', 'OR008', 'PC001', 'TP001', 'TP002', 'TP003'],
    };
  },
  computed: {
    errorMsg() {
      if (
        this.$route.query.code &&
        this.errorCodes.includes(this.$route.query.code)
      ) {
        try {
          return this.$i18n.t(`errorCodes.${this.$route.query.code}`);
        } catch {
          return this.$i18n.t('errors.unknownError');
        }
      }
      return null;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
